import React, {useState} from "react";
import colors from "../lib/colors";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Ui from "../components/Ui";
import Cookies from "cookies-js";
import fetch from "../lib/fetch";
import {XForm, rules} from "../lib/forms";
import {PlainButton, TransparentButton} from "../components/Button";
import {useAppInfo, setAppInfo} from "../features/AppInfo";
import DefaultLayout from "../components/DefaultLayout";
import GhostButton from "../components/ui/GhostButton";
import Col from "../components/ui/Col";
import Hero from "../components/bricks/Hero";
import ThemeBox from "../components/bricks/ThemeBox";
import {themeDark} from "../page-styles/landingpage.css";
import {useAuthFlow} from "../features/auth/oauth-flow";
import Row from "../components/ui/Row";
import {Box} from "../components/xui/Box";
import {ThemedButton} from "../components/xui/ThemedButton";
import Icon from "../components/Icon";
import cx from "../components/xui/cx";
import {loginStyles} from "../page-styles/loginpage.css";

const AccountButton = styled(GhostButton)({
  display: "block",
  ":not(:last-child)": {marginBottom: "1rem"},
});

const accountToLink = account => `https://${account.subdomain}${process.env.GATSBY_COOKIE_DOMAIN}`;

const Logout = () => {
  const handleLogout = () => {
    const token = Cookies.get("auth-token");
    const headers = token ? {"X-Auth-Token": token} : {};
    fetch(`/dispatch/users/logout`, {method: "post", headers, body: "{}"}).then(() => {
      if (token) Cookies.expire("auth-token", {domain: process.env.GATSBY_COOKIE_DOMAIN});
      window.location.reload();
    });
  };
  return (
    <PlainButton size="small" onClick={handleLogout}>
      Logout
    </PlainButton>
  );
};

const AccountPicker = ({accounts, hasSteamy}) => {
  return (
    <div css={css({textAlign: "center"})}>
      <Ui.H2 css={{marginBottom: "1rem"}}>Pick your organization:</Ui.H2>
      <div css={css({marginBottom: "2rem"})}>
        {accounts.map(account => {
          const link = `${account.subdomain}${process.env.GATSBY_COOKIE_DOMAIN}`;
          return (
            <AccountButton color="white" size="sm" href={`https://${link}`} key={account.id}>
              <div css={css({fontSize: "0.9rem", marginBottom: "0.25rem"})}>{account.name}</div>
              <div css={css({color: colors.fade(colors.white, 0.7)})}>{link}</div>
            </AccountButton>
          );
        })}
        {hasSteamy && (
          <GhostButton
            color="white"
            size="sm"
            href={`https://steamy${process.env.GATSBY_COOKIE_DOMAIN}`}
          >
            <div css={css({fontSize: "0.9rem", marginBottom: "0.25rem"})}>Open Steamy</div>
            <div css={css({color: colors.fade(colors.white, 0.7)})}>Steam Discord Bot</div>
          </GhostButton>
        )}
      </div>
      <Logout />
    </div>
  );
};

const LoginProviderButton = ({provider, children, onResult}) => {
  const buttonProps = useAuthFlow({
    provider,
    intent: "login",
    onDone: userId => {
      if (!userId) {
        onResult("No Codecks user was found with the provided auth method.");
      } else {
        onResult(null);
      }
    },
  });
  return (
    <ThemedButton size="sm" {...buttonProps}>
      {children}
    </ThemedButton>
  );
};

const AuthProviders = () => {
  const [error, setError] = useState(null);
  return (
    <Col sp={2}>
      <Row sp={2} align="center" justify="center">
        <Box bold size="sm" color="secondary">
          Sign in with
        </Box>
        <LoginProviderButton provider="discord" onResult={setError}>
          <Row sp={1} align="center">
            <Icon.Discord />
            <div>Discord</div>
          </Row>
        </LoginProviderButton>
        <LoginProviderButton provider="google" onResult={setError}>
          <Row sp={1} align="center">
            <Icon.Google />
            <div>Google</div>
          </Row>
        </LoginProviderButton>
      </Row>
      {error && (
        <Box color="purple300" bold textAlign="center" size="sm">
          {error}
        </Box>
      )}
    </Col>
  );
};

const BodyContent = styled("div")({
  width: "100%",
  maxWidth: "25rem",
  margin: "0 auto",
});

export const OrLine = ({children}) => (
  <Col relative align="center" px={4} className={cx(loginStyles.orContainer)}>
    <Box relative color="body" bg="bgBack" px={2} textAlign="center">
      {children}
    </Box>
  </Col>
);

const LoginForm = () => {
  const [showForgotPw, setForgotPw] = React.useState(false);
  const [resetSuccess, setResetSuccess] = React.useState(false);

  const handleLoginSubmit = ({email, password}) => {
    return fetch(`/services/remote-login`, {
      method: "POST",
      body: JSON.stringify({email, password}),
    }).then(
      ({userInfo: {accounts, name, email}}) => {
        if (accounts.length === 1) {
          window.location = accountToLink(accounts[0]);
        } else {
          setAppInfo({accounts, user: {name, email}});
        }
      },
      error => {
        const msgs = {
          rate_limit: "Too many log in attempts. Please wait 5 seconds.",
          no_user: {email: "There's no user with this email."},
          bad_password: {password: "Wrong password."},
          no_password: {
            password:
              "You haven't set up a password yet. Please use the 'forgot password' feature to set one up.",
          },
          only_discord: {
            password:
              "You've authenticated via Discord. Please use the button above. You can also use the 'forgot password' flow to set a password for your account.",
          },
          only_google: {
            password:
              "You've authenticated via Google. Please use the button above. You can also use the 'forgot password' flow to set a password for your account.",
          },
        };
        return Promise.reject(msgs[error] || error);
      }
    );
  };

  const handleForgotPwSubmit = ({email}) => {
    return fetch(`/services/request-password-reset`, {
      method: "POST",
      body: JSON.stringify({email}),
    }).then(() => setResetSuccess(true));
  };

  return resetSuccess ? (
    <div css={css({fontWeight: "bold", textAlign: "center"})}>Reset email was sent</div>
  ) : (
    <Col sp={4}>
      <AuthProviders />
      <OrLine>Or, use your email address</OrLine>
      <Col
        sp={4}
        as={XForm}
        onSubmit={showForgotPw ? handleForgotPwSubmit : handleLoginSubmit}
        buttonLabel={showForgotPw ? "Request reset link" : "Log in"}
        rules={{email: [rules.isRequired], password: showForgotPw ? [] : [rules.isRequired]}}
        initialValues={{email: "", password: ""}}
        px={2}
      >
        <XForm.Field
          name="email"
          label="Your email"
          placeholder="e.g. liz.mills@weyland-consortium.com"
        />
        <Col sp={1}>
          {showForgotPw ? null : (
            <XForm.Field
              type="password"
              name="password"
              label="Password"
              autoComplete="current-password"
            />
          )}
          <div>
            <TransparentButton color="dim" size="small" onClick={() => setForgotPw(!showForgotPw)}>
              {showForgotPw ? "Back to login" : "Forgot password?"}
            </TransparentButton>
          </div>
        </Col>
      </Col>
    </Col>
  );
};

const Login = ({location}) => {
  const {accounts, user, steamyGuilds} = useAppInfo();

  const hasAccounts =
    (accounts && accounts.length > 0) || (steamyGuilds && steamyGuilds.length > 0);

  return (
    <DefaultLayout title="Login" location={location}>
      <Hero size="sm" title={user ? `Hello ${user.name}!` : "Login"} noPaddingBottom />
      <ThemeBox theme={themeDark}>
        <BodyContent>
          {hasAccounts ? (
            <AccountPicker
              accounts={accounts}
              hasSteamy={steamyGuilds && steamyGuilds.length > 0}
            />
          ) : user ? (
            <div css={css({textAlign: "center", marginBottom: "1rem"})}>
              <div css={css({marginBottom: "1rem"})}>
                You don't seem to have access to any accounts right now.
              </div>
              <Logout />
            </div>
          ) : null}
          {!user && (!accounts || !accounts.length) && <LoginForm />}
          {!user && (
            <Col pt={7} css={css({textAlign: "center"})} align="center" sp={3}>
              <div css={css({fontSize: "0.8rem", fontWeight: "bold"})}>No account yet?</div>
              <GhostButton to="/create-account/" color="white">
                Create account
              </GhostButton>
            </Col>
          )}
        </BodyContent>
      </ThemeBox>
    </DefaultLayout>
  );
};

export default Login;
